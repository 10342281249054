<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Rates per room') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mini-gap is-mobi">
          <div class="column is-12">
            <Filter
              class="per-room-filter"
              column-size="is-narrow"
              @clear-filter="resetList"
              @filter="handleFilter"
            />
          </div>
        </div>
        <a-spin :spinning="isFetching">
          <div v-if="hasData">
            <div v-for="(dates, i) in weeklyGroup" :key="i">
              <a-table
                v-if="isRenderTable"
                row-key="name"
                :data-source="rows"
                :locale="tableLocale"
                :pagination="false"
                :scroll="{ x: 1250 }"
                class="price-table"
              >
                <a-table-column
                  v-if="dates && dates.length > 0"
                  key="name"
                  data-index="name"
                  :width="180"
                  :fixed="nameColFixed"
                >
                  <template #title>
                    <span class="rate-header has-text-left">{{ $t('Price') }}</span>
                  </template>
                </a-table-column>

                <a-table-column
                  v-for="date in dates"
                  :key="date"
                  :data-index="date"
                  :width="120"
                  align="right"
                >
                  <template #title>
                    <a-checkbox
                      v-model:value(v-model)="test"
                      size="md"
                      class="rate-header"
                      :class="dateClass(date) + (isDateOfMonth(date) ? '': ' not-in-month')"
                      :checked="rates.lockedDates[date]"
                      @change="handleCheckedDate(date)"
                    >
                      {{ $filters.date(date, { format: 'MM/DD ddd'}) }}
                    </a-checkbox>
                  </template>
                  <template #default="{ record }">
                    <div :class="(isDateOfMonth(date) ? '': ' not-in-month')">
                      <div v-if="record.editable === true && isDateOfMonth(date)">
                        <a-input
                          v-if="form.rates[date]"
                          v-model:value="form.rates[date].price"
                          type="number"
                          class="price-input has-text-right"
                          size="large"
                          :min="0"
                          @change="(e) => handlePriceChange(e.target.value, date)"
                        />
                        <span v-else class="price-text">-</span>
                      </div>
                      <span v-else class="price-text">{{
                        record.rates[date]
                          ? $filters.number(record.rates[date].price)
                          : 0
                      }}</span>
                    </div>
                  </template>
                </a-table-column>
              </a-table>
            </div>
          </div>
          <a-empty v-else />
        </a-spin>
        <div class="columns is-mini-gap is-mobi is-multiline">
          <div class="column has-text-right is-12 m-t-4x">
            <ExportButton
              v-permission="['export-rate']"
              :disabled="!canFetch()"
              :size="size"
              class="button-secondary-outline rate-save-btn"
              :date-from="dateFrom"
              :date-to="dateTo"
            />
            <Modal>
              <template #default="{ hide }">
                <SubmitNeppanConfirm
                  :rates="form.rates"
                  @confirmed="() => {
                    fetchFromQueries();
                    hide();
                  }"
                  @cancel="hide"
                />
              </template>
              <template #handler="{ show }">
                <slot name="handler" :show="show">
                  <a-button
                    v-permission="['modify-rate']"
                    :disabled="!canFetch() || allSubmitted"
                    :size="size"
                    class="rate-save-btn"
                    type="primary"
                    @click.prevent="show"
                  >
                    {{ $t('Submit to Neppan') }}
                  </a-button>
                  <a-button
                    v-permission="['modify-rate']"
                    :disabled="!canFetch() || allSubmitted"
                    :size="size"
                    class="rate-save-btn"
                    type="primary"
                    @click="handleSave"
                  >
                    {{ $t('Save') }}
                  </a-button>
                </slot>
              </template>
            </Modal>
          </div>
        </div>
      </div>
    </div>
    <NotSubmittedConfirm ref="notSubmittedModal" />
  </div>
</template>

<i18n>
{
  "en": {
    "Rates per room": "Rates per room",
    "no data": "Please select property, room, and plan name",
    "Submit to Neppan": "Submit to Neppan"
  },
  "ja": {
    "Rates per room": "１部屋あたりの料金",
    "no data": "物件、部屋、プラン名を選択してください",
    "Submit to Neppan": "ねっぱんに送信"
  }
}
</i18n>

<script>
import dayjs from 'dayjs';
import chunk from 'lodash/chunk';
import Modal from '@/components/Modal';
import { holidays } from '@/utils/holidays';
import { getResponseError } from '@/utils/util';
import { RATE_TYPES } from '@/config/revenue-managements';
import Filter from './components/Filter';
import SubmitNeppanConfirm from './components/SubmitNeppanConfirm';
import NotSubmittedConfirm from './components/NotSubmittedConfirm';
import ExportButton from './components/ExportButton';

export default {
  name: 'RatesPerRoom',
  components: {
    Modal,
    Filter,
    SubmitNeppanConfirm,
    NotSubmittedConfirm,
    ExportButton,
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasData && !this.allSubmitted) {
      this.$refs.notSubmittedModal.confirm(next);

      return;
    }

    next();
  },
  data() {
    return {
      size: 'large',
      nameColFixed: false,
      isFetching: false,
      tableLocale: {
        emptyText: this.$t('no data'),
      },
      form: {
        rates: {},
      },
      rates: {
        neppan: {},
        piop: {},
        registered: {},
        lockedDates: {},
      },
      priceAutoReflection: undefined,
      weeklyGroup: [],
      isRenderTable: true,
    };
  },
  computed: {
    dateFrom() {
      const from = this.$route.query.date;
      const dayNum = dayjs(from).startOf('month').day();

      // add missing days to start with sunday (0)
      const missing = (dayNum - 0);
      let dateFrom = dayjs(from).startOf('month');

      if (missing !== 0) {
        dateFrom = dateFrom.subtract(missing, 'day');
      }

      return dateFrom.format('YYYY-MM-DD');
    },
    dateTo() {
      const from = this.$route.query.date;
      const dayNum = dayjs(from).endOf('month').day();

      // add missing days to end with sat (6)
      const missing = (6 - dayNum);
      let dateTo = dayjs(from).endOf('month');

      if (missing !== 0) {
        dateTo = dateTo.add(missing, 'day');
      }

      return dateTo.format('YYYY-MM-DD');
    },
    rows() {
      if (!this.canFetch()) {
        return [];
      }

      const { neppan, piop, registered } = this.rates;

      return [
        {
          name: this.$t('Neppan Price'),
          rates: neppan,
        },
        {

          name: this.$t('PIOP Price'),
          rates: piop,
        },
        {
          name: this.$t('Registering Price'),
          rates: registered,
          editable: true,
        },
      ];
    },
    hasData() {
      return this.weeklyGroup && this.weeklyGroup.length > 0
      && this.rows && this.rows.length > 0;
    },
    allSubmitted() {
      const { rates } = this.form;
      const { piop } = this.rates;
      const dates = Object.keys(rates);

      for (let i = 0; i < dates.length; i += 1) {
        const date = dates[i];
        const { price, submitted } = rates[date];
        const registered = Number(price);
        const piopPrice = piop[date] ? piop[date].price : 0;

        if (
          (submitted === false && registered > 0) // not yet submitted
          && !(registered === 0 && piopPrice === 0) // not zero
        ) {
          return false;
        }
      }

      return true;
    },
    hasRoomAutoReflection() {
      return this.priceAutoReflection === 'room';
    },
  },
  async mounted() {
    this.setResponsiveColumns();

    window.addEventListener('resize', () => {
      this.setResponsiveColumns();
    });

    window.addEventListener('beforeunload', (e) => {
      if (this.hasData && !this.allSubmitted) {
        e = e || window.event;
        // old browsers
        if (e) {
          e.returnValue = 'Changes you made may not be saved';
        }
        // safari, chrome(chrome ignores text)
        return 'Changes you made may not be saved';
      }

      return null;
    });
  },
  async created() {
    await this.fetchFromQueries();
  },
  methods: {
    async handleFilter(query) {
      const handle = async () => {
        await this.$router.push({ query });
        await this.fetchFromQueries();
      };

      if (this.hasData && !this.allSubmitted) {
        this.$refs.notSubmittedModal.confirm(handle);

        return;
      }

      await handle();
    },
    handlePriceChange(newPrice, date) {
      if (this.form.rates[date]) {
        this.form.rates[date] = {
          price: newPrice,
          submitted: false,
          manuallyOverride: this.rates.piop[date]?.price !== Number(newPrice),
        };
      }
    },
    async handleCheckedDate(date) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      if (this.rates.lockedDates[date]) {
        await this.removeLockedDate(date);
      } else {
        await this.addLockedDate(date);
      }
      this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },
    async removeLockedDate(date) {
      const { id } = this.rates.lockedDates[date];

      await this.$store.dispatch('rms-locked-dates/deleteRecord', { id });
      delete this.rates.lockedDates[date];
    },
    async addLockedDate(date) {
      const payload = {
        date,
        propertyId: this.$route.query.propertyId,
      };
      const { data } = await this.$store.dispatch('rms-locked-dates/create', payload);

      this.rates.lockedDates[date] = data;
    },
    async fetchFromQueries() {
      if (!this.canFetch()) {
        return;
      }

      this.form.rates = {};

      const dates = this.getDates(dayjs(this.dateFrom), dayjs(this.dateTo));
      const [
        neppan,
        piop,
        registered,
        lockedDates,
        priceAutoReflection,
      ] = await this.fetchData(dates);

      this.rates.neppan = neppan;
      this.rates.piop = piop;
      this.rates.registered = registered;
      this.rates.lockedDates = lockedDates;
      this.priceAutoReflection = priceAutoReflection;

      this.weeklyGroup = chunk(dates, 7);
    },
    canFetch() {
      const { propertyId, plan, date } = this.$route.query;

      return propertyId && plan && date;
    },
    async fetchData(dates = []) {
      this.isFetching = true;

      try {
        const [neppan, piop, lockedDates, priceAutoReflection] = await Promise.all([
          this.fetchNeppanRates(dates),
          this.fetchPiopRates(dates),
          this.fetchLockedDates(dates),
          this.fetchAutoReflection(),
        ]);

        const registered = await this.fetchRegisteredRates(dates, piop, priceAutoReflection);

        return [neppan, piop, registered, lockedDates, priceAutoReflection];
      } finally {
        this.isFetching = false;
      }
    },
    async fetchNeppanRates(dates = []) {
      const { query } = this.$route;
      const neppanQuery = {
        ...query,
        dateFrom: dates[0],
        dateTo: dates[dates.length - 1],
      };

      const { data } = await this.$store.dispatch('rms-rates/neppan', neppanQuery);

      const rates = {};

      for (let i = 0; i < data.length; i += 1) {
        const { date, price } = data[i];
        rates[date] = { price };
      }

      return rates;
    },
    async fetchPiopRates(dates = []) {
      return this.doFetchRates(RATE_TYPES.PIOP_ROOM, dates);
    },
    async fetchRegisteredRates(dates = [], piopRates = {}, priceAutoReflection) {
      const rates = await this.doFetchRates(RATE_TYPES.ROOM, dates);
      const today = dayjs();

      // fill price textboxes
      for (let i = 0; i < dates.length; i += 1) {
        const date = dates[i];
        const record = rates[date];

        const piopPrice = piopRates[date] ? piopRates[date].price : 0;
        const price = this.registeredPrice(record, piopPrice, priceAutoReflection);
        const dateObject = dayjs(date);

        if (!dateObject.isBefore(today, 'day') && this.isDateOfMonth(date)) {
          this.form.rates[date] = {
            price,
            submitted: price === undefined,
            manuallyOverride: record?.manuallyOverride === true,
          };
        }
      }

      return rates;
    },
    async fetchLockedDates(dates = []) {
      const params = {
        ...this.$route.query,
        dateFrom: dates[0],
        dateTo: dates[dates.length - 1],
        infinite: true,
      };

      const { data } = await this.$store.dispatch('rms-locked-dates/list', params);

      const lockedDates = {};

      for (let i = 0; i < data.length; i += 1) {
        const { date, ...value } = data[i];
        lockedDates[date] = value;
      }

      return lockedDates;
    },
    async fetchAutoReflection() {
      const { priceAutoReflection } = await this.$store.dispatch('properties/priceAutoReflection', {
        propertyId: this.$route.query.propertyId,
      });

      return priceAutoReflection;
    },
    registeredPrice(record, piopPrice, priceAutoReflection) {
      if (record && record.submitted) {
        return undefined;
      }

      if (record && !record.submitted) {
        return record.price;
      }

      if (priceAutoReflection === 'room') {
        return undefined;
      }

      return piopPrice;
    },
    async doFetchRates(type, dates = []) {
      const params = {
        ...this.$route.query,
        dateFrom: dates[0],
        dateTo: dates[dates.length - 1],
        type,
      };

      const { data } = await this.$store.dispatch('rms-rates/list', params);
      const rates = {};

      for (let i = 0; i < data.length; i += 1) {
        const {
          date, price, submitted, manuallyOverride,
        } = data[i];
        rates[date] = {
          price,
          manuallyOverride,
          submitted: submitted === null ? true : submitted,
        };
      }

      return rates;
    },
    getDates(from, to) {
      const dates = [];
      let currentDate = from;

      while (currentDate <= to) {
        dates.push(dayjs(currentDate).format('YYYY-MM-DD'));
        currentDate = dayjs(currentDate).add(1, 'day');
      }

      return dates;
    },
    dateClass(date) {
      const dateObj = dayjs(date);
      const today = dayjs();

      if (dateObj.isSame(today, 'day')) {
        return 'is-today';
      }

      if (holidays.isHoliday(dateObj.format('YYYY-MM-DD'))) {
        return 'is-holiday';
      }

      const day = dateObj.day();

      if (day === 0 || day === 6) {
        return 'is-weekend';
      }

      return '';
    },
    isDateOfMonth(date) {
      const month = dayjs(this.$route.query.date).month();
      const otherMonth = dayjs(date).month();

      return month === otherMonth;
    },
    async handleSave() {
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');
        const { query } = this.$route;
        const payload = {
          propertyId: query.propertyId,
          rates: [],
        };

        const { rates } = this.form;
        const dates = Object.keys(rates) || [];
        for (let i = 0; i < dates.length; i += 1) {
          const date = dates[i];
          const { price } = rates[date];

          if (price !== undefined) {
            payload.rates.push({
              date,
              price: Number(rates[date].price),
              manuallyOverride: rates[date].manuallyOverride === true,
              plan: query.plan,
              submitted: false,
            });
          }
        }

        await this.$store.dispatch('rms-rates/saveByProperty', payload);
      } catch (error) {
        this.$message.error(getResponseError(error) || 'Error');
      } finally {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
    setResponsiveColumns() {
      this.isRenderTable = false;
      this.nameColFixed = false;

      const width = window.innerWidth;

      if (width > 480) {
        this.nameColFixed = 'left';
      }

      this.isRenderTable = true;
    },
    resetList() {
      this.rates = {
        neppan: {},
        piop: {},
        registered: {},
      };
      this.weeklyGroup = [];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rate-save-btn {
  margin-left: 0.8rem;
  margin-bottom: 0.8rem;
  min-width: 115px;
}

.per-room-filter::v-deep {
  .rate-filter-input {
    min-width: 150px;
  }
}

.price-input::v-deep {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  margin-right: -10px;
}

.price-table::v-deep {
  margin-top: 2rem;
  margin-bottom: 1rem;

  .not-in-month {
    opacity: 0.5;
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none
  }

  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 0;
  }

  .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 0;
  }

  .ant-table-thead > tr > th {
    padding: 2px 0;
    font-weight: bold;

    .ant-table-header-column {
      width: 100%;
    }

    .rate-header {
      color: #fff;
      display: block;
      padding: 14px 11px;
      border: 2px solid transparent;
    }

    .is-today {
      text-align: center;
      border: 2px solid white;
    }

    .is-weekend,
    .is-holiday {
      color: #FFC745;
    }
  }

  .ant-table-tbody > tr > td {
    padding: 16px;
  }
}
</style>
