import Holidays from 'date-holidays';

/**
 * currently only JP holidays are considered,
 * use this instance to add custom holidays.
 *
 * @see https://github.com/commenthol/date-holidays-parser/blob/master/docs/Holidays.md
 */

export const holidays = new Holidays('JP');

export default holidays;
