<template>
  <a-button
    v-permission="['export-rate']"
    :disabled="disabled"
    :size="size"
    @click="handleExportCSV"
  >
    {{ $t('Export to CSV') }}
  </a-button>
</template>

<script>
import { saveAs } from 'file-saver';
import { getLocale } from '@/utils/locale';
import { getDefaultLang } from '@/utils/language';

export default {
  name: 'ExportButton',
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'large',
    },
    dateFrom: {
      type: String,
      required: true,
    },
    dateTo: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleExportCSV() {
      const { plan, propertyId } = this.$route.query;

      const params = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        plan,
        propertyId,
        locale: getLocale() || getDefaultLang(),
      };

      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      this.$store
        .dispatch('rms-rates/exportRates', params)
        .then(({ data, headers }) => {
          const newBlob = new Blob([data]);
          const filename = headers['content-disposition'].replace('attachment; filename=', '');
          saveAs(newBlob, filename);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
  },
};
</script>

<style>
</style>
