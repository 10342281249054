<template>
  <div>
    <div v-if="step === 1" class="columns is-multiline">
      <div class="column is-12">
        <h3 class="has-text-primary has-text-weight-bold is-size-4">
          <i class="el-icon-warning has-text-danger" /> {{ $t('Confirm') }}
        </h3>
      </div>
      <div class="column is-12">
        <p> {{ $t('description') }} </p>
      </div>
      <div class="column has-text-right is-12">
        <a-button
          class="m-r-2x"
          style="min-width: 125px"
          :disabled="loading"
          @click="handleCancel"
        >
          {{ $t('Cancel') }}
        </a-button>
        <a-button
          type="primary"
          style="min-width: 125px"
          :loading="loading"
          @click="handleConfirm"
        >
          {{ $t('Confirm') }}
        </a-button>
      </div>
    </div>
    <div v-if="step === 2" class="columns is-multiline">
      <div class="column is-12">
        <h3 class="has-text-secondary has-text-weight-bold is-size-4">
          {{ $t('Success') }}
        </h3>
      </div>
      <div class="column is-12">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="$t('success-message')" />
      </div>
      <div class="column has-text-right is-12">
        <a-button
          class="button-secondary-outline"
          style="min-width: 125px"
          @click="handleConfirmed"
        >
          {{ $t('Ok') }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "description": "Are you sure you want to submit the rates to Neppan?",
    "Confirm": "Confirm",
    "Cancel": "Cancel",
    "Success": "Success",
    "success-message": "Rates have been submitted to Neppan.",
    "Ok": "Ok",
  },
  "ja": {
    "description": "この料金をねっぱんに送信します。よろしいですか？",
    "Confirm": "承認",
    "Cancel": "取り消し",
    "Success": "料金更新",
    "success-message": "料金をねっぱんに送信しました。",
    "Ok": "Ok",
  }
}
</i18n>

<script>
import dayjs from 'dayjs';

export default {
  name: 'SubmitNeppanConfirm',
  components: {},
  props: {
    rates: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  emits: ['cancel', 'confirmed'],
  data() {
    return {
      step: 1,
      loading: false,
    };
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleConfirmed() {
      this.$emit('confirmed');
    },
    async handleConfirm() {
      try {
        this.loading = true;
        const { query } = this.$route;
        const payload = {
          propertyId: query.propertyId,
          rates: [],
        };

        const dates = Object.keys(this.rates) || [];
        for (let i = 0; i < dates.length; i += 1) {
          const date = dates[i];
          const { price, manuallyOverride } = this.rates[date];

          if (price !== undefined && price > 0 && this.isDateOfMonth(date)) {
            payload.rates.push({
              date,
              price: Number(price),
              plan: query.plan,
              manuallyOverride,
            });
          }
        }

        await this.$store.dispatch('rms-rates/submitToNeppan', payload);
        this.loading = false;
        this.step = 2;
      } catch (error) {
        this.loading = false;
      }
    },
    isDateOfMonth(date) {
      const month = dayjs(this.$route.query.date).month();
      const otherMonth = dayjs(date).month();

      return month === otherMonth;
    },
  },
};
</script>
