<template>
  <Modal ref="modal">
    <template #default="{ hide }">
      <div class="columns is-multiline">
        <div class="column is-12">
          <h3 class="has-text-primary has-text-weight-bold is-size-4">
            <i class="el-icon-warning has-text-danger" /> {{ $t('Confirm') }}
          </h3>
        </div>
        <div class="column is-12">
          <p> {{ $t('description') }} </p>
        </div>
        <div class="column has-text-right is-12">
          <a-button
            class="m-r-2x"
            style="min-width: 125px"
            :disabled="loading"
            @click="hide"
          >
            {{ $t('Cancel') }}
          </a-button>
          <a-button
            type="primary"
            style="min-width: 125px"
            :loading="loading"
            @click="handleConfirm"
          >
            {{ $t('Confirm') }}
          </a-button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<i18n>
{
  "en": {
    "description": "There are still unsubmitted rates, are you sure you want leave this page?",
    "Confirm": "Confirm",
    "Cancel": "Cancel",
    "Ok": "Ok",
  },
  "ja": {
    "description": "There are still unsubmitted rates, are you sure you want leave this page?",
    "Confirm": "承認",
    "Cancel": "取り消し",
    "Ok": "Ok",
  }
}
</i18n>

<script>
import Modal from '@/components/Modal';
import { getResponseError } from '@/utils/util';

export default {
  name: 'NotSubmittedConfirm',
  components: { Modal },
  emits: ['cancel', 'confirmed'],
  data() {
    return {
      loading: false,
      handler: null,
    };
  },
  methods: {
    async handleConfirm() {
      if (!this.handler || typeof this.handler !== 'function') {
        this.confirmed();

        return;
      }

      try {
        this.loading = true;
        this.confirmed();
        await this.handler();
      } catch (error) {
        this.$message.error(getResponseError(error));
        this.confirmed();
      }
    },
    confirm(handler) {
      this.handler = handler;
      this.$refs.modal.show();
    },
    confirmed() {
      this.loading = false;
      this.$refs.modal.hide();
    },
  },
};
</script>
